import { useQuery } from "@tanstack/react-query";
import { useConfigurationStore } from "./configurationStore";
import { CardProvider, PaymentMethod } from "../payment/types/PaymentMethod";
import { usePaymentStore } from "../payment/stores/paymentStore";
import { useAuthenticationStore } from "../authentication/authenticationStore";
import {
  MemberpackObtainingMethod,
  useHmpOwnershipStore,
} from "../personal-info/hmpOwnership/hmpOwnershipStore";
import axios from "axios";
import { CONFIG_PATH } from "../../config/api";

type SupportedPaymentMethods = {
  methods: PaymentMethod[];
  cardProviders: CardProvider[];
};

export type ConfigurationResponse = {
  version: string;
  supportedPaymentMethods: SupportedPaymentMethods;
  supportedMemberpackObtainingMethods: MemberpackObtainingMethod[];
};

const { setVersion } = useConfigurationStore.getState();
const { setPaymentMethodsAndCardProviders } = usePaymentStore.getState();
const { setSupportedMemberpackObtainingMethods } =
  useHmpOwnershipStore.getState();

const fetchConfig = async (): Promise<ConfigurationResponse> => {
  const response = await axios.get<ConfigurationResponse>(CONFIG_PATH, {
    timeout: 15000,
  });
  return response.data;
};

const configuration = async () => {
  const data = await fetchConfig();
  const {
    version,
    supportedPaymentMethods: { methods, cardProviders },
    supportedMemberpackObtainingMethods,
  } = data;
  setVersion(version);
  setPaymentMethodsAndCardProviders(methods, cardProviders);
  setSupportedMemberpackObtainingMethods(supportedMemberpackObtainingMethods);
  return data;
};

export const useConfigurationQuery = () =>
  useQuery<ConfigurationResponse>({
    queryKey: ["configuration"],
    queryFn: configuration,
    enabled:
      useAuthenticationStore.getState().isAuthenticated() &&
      useConfigurationStore.getState().isVersionNotObtained(),
    retry: 3,
  });
