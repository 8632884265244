import { useProfileStore } from "../initialRegistration/profileStore";

export enum DeliveryMethod {
  SHIPPING = "SHIPPING",
  PICKUP = "PICKUP",
}

export const supportsShipping = () =>
  import.meta.env.VITE_COUNTRIES_WITH_SHIPPING.includes(
    useProfileStore.getState().country
  );

export const supportsPickup = () =>
  import.meta.env.VITE_COUNTRIES_WITH_PICKUP.includes(
    useProfileStore.getState().country
  );

export const getDeliveryMethods = (): DeliveryMethod[] => {
  const deliveryMethods: DeliveryMethod[] = [];

  if (supportsShipping()) {
    deliveryMethods.push(DeliveryMethod.SHIPPING);
  }

  if (supportsPickup()) {
    deliveryMethods.push(DeliveryMethod.PICKUP);
  }

  return deliveryMethods;
};

export const supportsShippingAndPickup = () => {
  return supportsShipping() && supportsPickup();
};
